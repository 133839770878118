import React, { useState, useEffect, useRef } from 'react';
import {
    Grid,
    Container,
    Box
} from '@mui/material';
import Banner from '../../components/banner';
import CardBigNewsHighlight from '../../components/card-group/card-big-news-highlight';
import CardMiddleRow from '../../components/card-group/card-middle-row';
import ListNews from '../../components/list/list-news';
import ListLiveScoreList from '../../components/list/list-livescore-list';
import PlayerSection from '../../components/section/player-section';
import ListLiveScoreCard from '../../components/list/list-livescore-card';
import Ad from '../../components/ad';
import ad from '../../ad-v-01.png';
import ad2 from '../../gpp-01.png';

export default function HomeMDLayout({ news_highlight, news_card, news_list, match_list, video_list, live_list }) {
    return (
        <>
            <Container
                disableGutters={true}
                maxWidth="xl"
                sx={{
                    display: {
                        md: 'flex',
                        xs: 'none',
                        sm: 'none',
                    },
                    flexDirection: 'column',
                    columnGap: 2,
                    rowGap: 2,
                    pl: 2,
                    pr: 2
                }}
            >
                <Grid
                    direction="row"
                    container
                    spacing={2}
                    rowSpacing={2}
                    style={{
                        marginTop:1
                    }}
                >
                    <Grid
                        item
                        md={9}
                    >
                        <Banner />
                    </Grid>
                    <Grid
                        item
                        md={3}
                    >
                        {/* <ListLiveScoreList
                            match_list={match_list}
                        /> */}
                        <img
                            src={ad}
                            style={{ position: "center", width: '100%',
                            height: '100%', }}
                        />
                    </Grid>
                </Grid>

                <Grid
                    container
                    spacing={2}
                    rowSpacing={2}
                >

{/* <Grid
                        item
                        md={10}
                    >
                        <ListLiveScoreCard
                            match_list={match_list}
                        />
                    </Grid> */}
                    <Grid
                        item
                        md={12}
                    >
                        <PlayerSection
                            section_title={"Live"}
                            content_list={live_list}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    rowSpacing={2}
                >
                    <Grid
                        item
                        md={9}
                    >
                        <CardBigNewsHighlight
                            news_highlight={news_highlight}
                            title={'ไฮไลท์ข่าว'}
                        />
                    </Grid>
                    <Grid
                        item
                        md={3}
                    >
                        {/* <Ad/> */}
                        <Box
                            style={{
                                top: 10,
                                bottom: 0,
                                position: '-webkit - sticky', /* Safari */
                                position: 'sticky',
                            }}
                        >
                            <img
                                src={ad2}
                                style={{ position: "relative", width: '100%' }}
                            />
                            </Box>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    rowSpacing={2}
                >
                    <Grid
                        item
                        md={12}
                    >
                        <PlayerSection
                            section_title={"Video"}
                            content_list={video_list}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    rowSpacing={2}
                >
                    <Grid
                        item
                        md={3}
                    >
                        <ListNews
                            news={news_list}
                        />
                    </Grid>
                    <Grid
                        item
                        md={3}
                    >
                        <CardMiddleRow
                            news={news_card.slice(0, 2)}
                        />
                    </Grid>
                    <Grid
                        item
                        md={3}
                    >
                        <CardMiddleRow
                            news={news_card.slice(2, 4)}
                        />
                    </Grid>
                    <Grid
                        item
                        md={3}
                    >
                        <CardMiddleRow
                            news={news_card.slice(4, 6)}
                        />
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}