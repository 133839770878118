import React, { useState, useEffect, useRef } from 'react';
import {
    Grid,
    Container,
    Box
} from '@mui/material';
import Banner from '../../components/banner';
import CardBigNewsHighlight from '../../components/card-group/card-big-news-highlight';
import CardMiddleRow from '../../components/card-group/card-middle-row';
import CardMiddleCol from '../../components/card-group/card-middle-col';
import ListNews from '../../components/list/list-news';
import ListLiveScoreCard from '../../components/list/list-livescore-card';
import VideoSection from '../../components/section/video-section';
import PlayerSection from '../../components/section/player-section';
import Ad from '../../components/ad';
import ad from '../../ad-h-01.png';
import ad2 from '../../gpp-01.png';

export default function HomeSMLayout({ news_highlight, news_card, news_list, match_list, video_list, live_list }) {
    return (
        <>
        <Container
                disableGutters={true}
                maxWidth="md"
                sx={{
                    display: {
                        md: 'none',
                        xs: 'none',
                        sm: 'flex',
                    },
                    flexDirection: 'column',
                    // columnGap: 2,
                    // rowGap: 2,
                    // pl: 2,
                    // pr: 2
                }}

            >

<Grid
                    container
                    // spacing={1}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <Banner />
                    </Grid>
                </Grid>
                </Container>
            <Container
                disableGutters={true}
                maxWidth="lg"
                sx={{
                    display: {
                        md: 'none',
                        xs: 'none',
                        sm: 'flex',
                    },
                    flexDirection: 'column',
                    columnGap: 2,
                    rowGap: 2,
                    pl: 2,
                    pr: 2
                }}
            >
                <Grid
                    container
                    spacing={1}
                    rowSpacing={2}
                >
                    {/* <Grid
                        item
                        sm={12}
                    >
                        <Banner />
                    </Grid> */}
                    {/* <Grid
                        item
                        sm={12}
                    >
                        <ListLiveScoreCard
                            match_list={match_list}
                        />
                    </Grid> */}
                    <Grid
                        item
                        sm={12}
                    >
                        <PlayerSection
                            section_title={"Live"}
                            content_list={live_list}
                        />
                    </Grid>
                    <Grid
                        item
                        sm={12}
                    >
                        <CardBigNewsHighlight
                            news_highlight={news_highlight}
                            title={'ไฮไลท์ข่าว'}
                        />
                    </Grid>

                    <Grid
                        item
                        sm={12}
                    >
                        <img
                            src={ad}
                            style={{ position: "relative", width: '100%' }}
                        />
                    </Grid>

                    <Grid
                        item
                        sm={12}
                    >
                        <PlayerSection
                            section_title={"Video"}
                            content_list={video_list}
                        />
                    </Grid>
                    <Grid
                        item
                        sm={4}
                    >
                        <ListNews
                            news={news_list}
                        />
                    </Grid>
                    <Grid
                        item
                        sm={4}
                    >
                        <CardMiddleRow
                            news={news_card.slice(0, 2)}
                        />
                    </Grid>

                    <Grid
                        item
                        sm={4}
                    >
                        <Box
                            style={{
                                top: 10,
                                bottom: 0,
                                position: '-webkit - sticky', /* Safari */
                                position: 'sticky',
                            }}
                        >
                            <img
                                src={ad2}
                                style={{ position: "relative", width: '100%' }}
                            />
                            {/*  
            <Box
                sx={{
                    width: '100%',
                    height: 550,
                    // backgroundColor: 'primary.dark',
                    backgroundColor: '#111111',
                    '&:hover': {
                        // backgroundColor: 'primary.dark',
                        backgroundColor: '#111111',
                        opacity: [0.9, 0.8, 0.7],
                    },
                    // display: 'inline-block'
                }}
                style={{
                    position: '-webkit - sticky', 
                    position: 'sticky',
                    // top: 10,
                    backgroundImage: `url(${ad})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
            >
            </Box>
            <Box
                sx={{
                    width: '100%',
                    height: 550,
                    // backgroundColor: 'primary.dark',
                    backgroundColor: '#111111',
                    '&:hover': {
                        // backgroundColor: 'primary.dark',
                        backgroundColor: '#111111',
                        opacity: [0.9, 0.8, 0.7],
                    },
                    // display: 'inline-block'
                }}
                style={{
                    position: '-webkit - sticky', 
                    position: 'sticky',
                    // top: 0,
                    backgroundImage: `url(${ad2})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
            >
            </Box>
        */}
                        </Box>
                    </Grid>
                    <Grid
                        item
                        sm={12}
                    >
                        <CardMiddleCol
                            news={news_card.slice(3, 6)}
                        />
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}