import React, { useState } from 'react';
import {
    AppBar,
    Container,
    Box,
    Button,
    IconButton,
    Drawer,
    List,
    ListItem,
    Divider,
    Toolbar,
} from '@mui/material';
import logo from '../logo.png';
import MenuIcon from '@mui/icons-material/Menu';
import ListItemComponent from './list/list-item-component';
import SocialIconGroup from './icon-group/social-icon-group';

export default function Header({ video_list, live_list }) {
    const [is_open_menu, setIsOpenMenu] = useState(false);
    const [anchorElNav, setAnchorElNav] = useState(null);

    const handleOpenNavMenu = (event) => {
        setIsOpenMenu(event.currentTarget);
    };

    const handleChangePage = (url) => {
        // event.preventdefault()
        setAnchorElNav(null);
        window.location = url
    }
    return (
        <>
            <AppBar
                position="fixed"
                sx={{ backgroundColor: '#111111' }}
                style={{
                    'border-bottom': '4px solid #FE5907',
                }}
            >
                <Container
                    maxWidth="false"
                >
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'flex', md: 'none' },
                        }}
                        position="right"
                    >
                        <img
                            src={logo}
                            component="a"
                            href="/"
                            className='logo-img'
                        />
                        <Button sx={{ flexGrow: 1 }} color="inherit">
                        </Button>
                        <IconButton
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Drawer
                            width={250}
                            open={is_open_menu}
                            onClose={() => { setIsOpenMenu(false) }}
                            anchor='right'
                        >
                            <Box
                                sx={{ width: 200, height: '100%' }}
                                style={{
                                    backgroundColor: '#393939'
                                }}
                            >
                                <List
                                    style={{
                                        backgroundColor: '#393939'
                                    }}
                                >
                                    <ListItem
                                        disablePadding
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            height: '55px'
                                        }}
                                    >
                                        <SocialIconGroup />
                                    </ListItem>
                                    <ListItemComponent
                                        bg_color={"#111111"}
                                        primary_text={"Home"}
                                        handleClick={() => handleChangePage('/')}
                                    />
                                    <ListItemComponent
                                        bg_color={"#111111"}
                                        primary_text={"News"}
                                        handleClick={() => handleChangePage('/news')}
                                    />
                                    {
                                        video_list !== undefined ?
                                            video_list.length > 0 ?
                                                <ListItemComponent
                                                    bg_color={"#111111"}
                                                    primary_text={"Videos"}
                                                />
                                                : null
                                            : null
                                    }
                                    {
                                        live_list !== undefined ?
                                            live_list.length > 0 ?
                                                <ListItemComponent
                                                    bg_color={"#111111"}
                                                    primary_text={"Live"}
                                                />
                                                : null
                                            : null
                                    }
                                    {/* <ListItemComponent
                                        bg_color={"#111111"}
                                        primary_text={"Live score"}
                                        handleClick={()=>handleChangePage('/live-score')}
                                    /> */}
                                    <Divider sx={{ bgcolor: "#111111" }} />
                                </List>
                            </Box>
                        </Drawer>
                    </Box>
                    <Container
                        maxWidth="xl"
                        disableGutters={true}
                    >
                        <Toolbar disableGutters style={{ minHeight: 0 }}>
                            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                <img
                                    src={logo}
                                    component="a"
                                    href="/"
                                    className='logo-img'
                                />
                                <Button
                                    onClick={() => handleChangePage('/')}
                                    sx={{ color: 'white', display: 'block' }}
                                    size={'large'}
                                >
                                    Home
                                </Button>
                                <Button
                                    onClick={() => handleChangePage('/news')}
                                    sx={{ color: 'white', display: 'block' }}
                                    size={'large'}
                                >
                                    News
                                </Button>
                                {
                                    video_list !== undefined ?
                                        video_list.length > 0 ?
                                            <Button
                                                onClick={() => handleChangePage('/')}
                                                sx={{ color: 'white', display: 'block' }}
                                                size={'large'}
                                            >
                                                Video
                                            </Button>
                                            : null
                                        : null
                                }
                                {
                                    live_list !== undefined ?
                                        live_list.length > 0 ?

                                            <Button
                                                onClick={() => handleChangePage('/')}
                                                sx={{ color: 'white', display: 'block' }}
                                                size={'large'}
                                            >
                                                Live
                                            </Button>
                                            : null
                                        : null
                                }
                                {/* <Button
                                    onClick={() => handleChangePage('/live')}
                                    sx={{ color: 'white', display: 'block' }}
                                    size={'large'}
                                >
                                    Live
                                </Button> */}
                                {/* <Button
                                    onClick={() => handleChangePage('/live-score')}
                                    sx={{ color: 'white', display: 'block' }}
                                    size={'large'}
                                >
                                    Live Score
                                </Button> */}
                            </Box>
                            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                <SocialIconGroup />
                            </Box>
                        </Toolbar>
                    </Container>
                </Container>
            </AppBar>
        </>
    )
}