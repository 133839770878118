import axios from 'axios';
import config from '../config.json';

export const getAllCategory = async () => {
    return axios.get(
        config.BASE_API + 'web/categorys?active=true',
        {
            headers: {
                'Content-Type': 'application/json',
            }
        }
    )
}