import React, { useState, useEffect } from 'react'
import {
    Box,
    Typography,
} from '@mui/material';
import ad from '../ad-v-01.png';
import ad2 from '../gpp-01.png';

export default function Ad({ }) {
    return (
        <Box
            style={{
                top:20,
                bottom:0,
                position: '-webkit - sticky', /* Safari */
                position: 'sticky',
            }}
        >
            {/* <img
                src={ad}
                style={{ position: "relative", width: '100%' }}
            />
            <img
                src={ad2}
                style={{ position: "relative", width: '100%' }}
            /> */}
             
            <Box
                sx={{
                    width: '100%',
                    height: 550,
                    // backgroundColor: 'primary.dark',
                    backgroundColor: '#111111',
                    '&:hover': {
                        // backgroundColor: 'primary.dark',
                        backgroundColor: '#111111',
                        opacity: [0.9, 0.8, 0.7],
                    },
                    // display: 'inline-block'
                }}
                style={{
                    // position: '-webkit - sticky', 
                    // position: 'sticky',
                    // top: 10,
                    backgroundImage: `url(${ad})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
            >
            </Box>
            <Box
                sx={{
                    width: '100%',
                    height: 550,
                    // backgroundColor: 'primary.dark',
                    backgroundColor: '#111111',
                    '&:hover': {
                        // backgroundColor: 'primary.dark',
                        backgroundColor: '#111111',
                        opacity: [0.9, 0.8, 0.7],
                    },
                    // display: 'inline-block'
                }}
                style={{
                    // position: '-webkit - sticky', 
                    // position: 'sticky',
                    // top: 0,
                    backgroundImage: `url(${ad2})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
            >
            </Box>
        
        </Box>
    )
}