import React from 'react';
import {
    ListItem,
    Divider,
    ListItemText,
    ListItemButton,
    Typography,
} from '@mui/material';


export default function ListItemComponent({
    bg_color,
    handleClick,
    primary_text,
    secondary_text,
}) {
    return (
        <>
            <Divider sx={{ bgcolor: bg_color }} />
            <ListItem
                disablePadding
                onClick={handleClick}
            >
                <ListItemButton>
                    <ListItemText
                        primary={
                            <Typography
                                variant="body2"
                                style={{ color: '#FFFFFF' }}
                            >
                                {primary_text}
                            </Typography>
                        }
                        secondary={
                            <Typography
                                variant="body2"
                                style={{ color: '#FFFFFF' }}
                            >
                                {secondary_text}
                            </Typography>
                        }
                        onClick={handleClick}
                    />
                </ListItemButton>
            </ListItem>
        </>
    )
}