import axios from 'axios';
import config from '../config.json';

export const getVideo = async () => {
    return axios.get(
      config.BASE_API + 'web/video?active=true',
      // config.BASE_API + 'web/video?active=false',
      {
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
  }