import React, { useState, useEffect } from 'react'
import {
    Box,
    Typography,
} from '@mui/material';
import ad from '../ad-h-01.png';

export default function AdMobile({ }) {
    return (
        <Box
            sx={{
                width: '100%',
                height: 250,
                // backgroundColor: 'primary.dark',
                backgroundColor: '#111111',
                '&:hover': {
                    // backgroundColor: 'primary.dark',
                    backgroundColor: '#111111',
                    opacity: [0.9, 0.8, 0.7],
                },
                // display: 'inline-block'
            }}
            style={{
                // position: '-webkit - sticky', /* Safari */
                // position: 'sticky',
                // top: 10,
                backgroundImage: `url(${ad})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}
        >
            {/* <Typography
                variant="h2"
                gutterBottom
                className='header-font'
            >
                <br />พื้นที่เพื่อการโฆษณา
            </Typography> */}
        </Box>
    )
}