import React, { useState, useEffect } from 'react';
import { getNews, getNewsDetail } from '../services/news-service';
import { timeDifference } from '../utils/display-utils';

export default function useNewsHook(path) {
    const [news_highlight, setNewsHighlight] = useState([])
    const [news_list_home, setNewsListHome] = useState([])
    const [news_card, setNewsCard] = useState([])
    const [news_list, setNewsList] = useState([])
    const [news_all, setNewsAll] = useState([])
    const [filter_str, setFilterStr] = useState("")
    const [search_str, setSearchStr] = useState("")
    const [selected_news_category, setSelectedNewsCategory] = useState(0)

    useEffect(() => {
        fetchNewsHome("&limit=20")
        fetchNews("")
    }, [])

    const fetchNewsHome = async (filter) => {
        // console.log("eiei");
        let res = await getNews(filter)
        let all_news = res.data.data
        let temp_news_list = []
        console.log(all_news[0]);
        let count = 0
        for(let item of all_news){
            // console.log(/^[\u0000-\u007f]*$/.test(item.news_thumbnail));
            // console.log(item.news_subject);
            // if(count < 20){
            //     count++
            // }else{
            //     break
            // }
            if (item.news_thumbnail !== "news_thumbnail" && /^[\u0000-\u007f]*$/.test(item.news_thumbnail) ) {
                // log
                temp_news_list.push({
                    news_id: item.news_id,
                    news_thumbnail: item.news_thumbnail?.replace(" ", ""),
                    news_subject: item.news_subject,
                    news_body: item.news_body,
                    approvedAt: timeDifference(item.approvedAt),
                    username: item.username,
                    category_name: item.category_name,
                    news_uid: item.news_uid
                })
            }
        }
        setNewsAll(temp_news_list)
        setNewsHighlight(temp_news_list.slice(0,3))
        setNewsCard(temp_news_list.slice(3,9))
        setNewsListHome(temp_news_list.slice(9,15))
    }

    const fetchNews = async (filter) => {
        // console.log("eiei");
        let res = await getNews(filter)
        let all_news = res.data.data
        let temp_news_list = []
        console.log(all_news[0]);
        let count = 0
        for(let item of all_news){
            // console.log(/^[\u0000-\u007f]*$/.test(item.news_thumbnail));
            // console.log(item.news_subject);
            // if(count < 20){
            //     count++
            // }else{
            //     break
            // }
            if (item.news_thumbnail !== "news_thumbnail" && /^[\u0000-\u007f]*$/.test(item.news_thumbnail) ) {
                // log
                temp_news_list.push({
                    news_id: item.news_id,
                    news_thumbnail: item.news_thumbnail?.replace(" ", ""),
                    news_subject: item.news_subject,
                    news_body: item.news_body,
                    approvedAt: timeDifference(item.approvedAt),
                    username: item.username,
                    category_name: item.category_name,
                    news_uid: item.news_uid
                })
            }
        }
        setNewsAll(temp_news_list)
        setNewsHighlight(temp_news_list.slice(0,3))
        setNewsCard(temp_news_list.slice(3,9))
        setNewsList(temp_news_list.slice(9,15))
    }

    const handleSearchSubmit = () =>{
        console.log(search_str);
        let filter = ""
        if(selected_news_category !== 0){
            filter += "&category_id="+selected_news_category
        }
        if(search_str !== ""){
            filter += "&search="+search_str
        }
        fetchNews(filter)
    }

    const handleCategorySelected = (category_id) => {
        let filter = ""
        setSelectedNewsCategory(category_id)
        if(category_id !== 0){
            filter += "&category_id="+category_id
            setSelectedNewsCategory(category_id)
        }
        if(search_str !== ""){
            filter += "&search="+search_str
        }
        fetchNews(filter)
    }

    const fetchNewsDetails = async (uid) => {
        let res = getNewsDetail(uid)
        return(res)
    }

    return{
        news_highlight,
        news_card,
        news_list,
        news_all,
        search_str,
        setSearchStr,
        handleSearchSubmit,
        selected_news_category,
        setSelectedNewsCategory,
        handleCategorySelected,
        fetchNewsDetails,
        news_list_home
    }
}