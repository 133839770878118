import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
    Container,
} from '@mui/material';
import Header from '../components/header';
import { SuperSEO } from "react-super-seo";
import useNewsHook from '../hooks/news-hook';
import useVideoHook from '../hooks/video-hook';
import useLiveHook from '../hooks/live-hook';
import NewsDetailMDLayout from '../layouts/news-detail/news-detail-md-layout';
import NewsDetailSMLayout from '../layouts/news-detail/news-detail-sm-layout';
import NewsDetailXSLayout from '../layouts/news-detail/news-detail-xs-layout';

export default function NewsDetailsPage() {
    const { uid } = useParams();
    const { video_list } = useVideoHook()
    const { live_list } = useLiveHook()
    const { fetchNewsDetails, news_all } = useNewsHook()
    const [news_details, setNewsDetails] = useState([])

    useEffect(() => {
        const asyncFn = async () => {
            console.log("news_uid", uid);
            let res = await fetchNewsDetails(uid)
            console.log('news', res.data.data);
            setNewsDetails(res.data.data[0])
        }
        asyncFn()
    }, [])

    return (
        <>
            <SuperSEO
                title="Thai Sport Plus"
                description="สถานีกีฬา 24 ชั่วโมง : ติดตามบทวิเคราะห์สัมภาษณ์นักกีฬาอย่างเจาะลึกตรงประเด็น"
                lang="en"
                openGraph={{
                    ogTitle: "Thai sport plus",
                    ogType: "website",
                    ogDescription: news_details.news_subject,
                    ogImage: {
                        ogImage: news_details.news_thumbnail,
                        ogImageAlt: "news",
                        ogImageWidth: 1200,
                        ogImageHeight: 630,
                        ogImageType: "image/jpeg",
                    },
                }}
            />
            {/* <SuperSEO
                title="Thai Sport Plus"
                description="สถานีกีฬา 24 ชั่วโมง : ติดตามบทวิเคราะห์สัมภาษณ์นักกีฬาอย่างเจาะลึกตรงประเด็น"
                lang="en"
                openGraph={{
                    ogTitle: "Thai sport plus",
                    ogType: "article",
                    ogDescription: "สถานีกีฬา 24 ชั่วโมง : ติดตามบทวิเคราะห์สัมภาษณ์นักกีฬาอย่างเจาะลึกตรงประเด็น",
                    ogImage: {
                        ogImage: "https://thaisportplus.com/static/media/logo.d20e9b6809f1ea4b254c.png",
                        ogImageAlt: "news",
                        ogImageWidth: 1200,
                        ogImageHeight: 630,
                        ogImageType: "image/jpeg",
                    },
                }}
            /> */}
            <Header
                video_list={video_list}
                live_list={live_list}
            />
            <Container
                disableGutters={true}
                maxWidth="false"
                className='web-bg'
            >
                <NewsDetailMDLayout
                    news_details={news_details}
                    news_all={news_all}
                />
                <NewsDetailSMLayout
                    news_details={news_details}
                    news_all={news_all}
                />
                <NewsDetailXSLayout
                    news_details={news_details}
                    news_all={news_all}
                />
            </Container>
        </>
    )
}
