import React, { useState, useEffect, useRef } from 'react';
import {
    Grid,
    Typography,
} from '@mui/material';
import CardMiddle from '../card/card-middle';
import CardSmall from '../card/card-small';

export default function CardSmallNewsHighlight({ news_highlight, title }) {
    const handleCardClick = (uid) =>{
        if (uid === 0) {
            window.location = "/news"
        } else {
            window.location = "/news/" + uid
        }
    }
    return (
        <>
            {
                news_highlight !== undefined ?
                    news_highlight.length > 0 ?
                        <>
                            <Typography
                                className='section-header-white2'
                                style={{
                                    'border-bottom': '4px solid #FE5907',
                                    'inline-size': '150px',
                                }}
                            >
                                {title}
                            </Typography>
                            <Grid
                                sx={{ mt: 1 }}
                                container
                                spacing={2}
                                rowSpacing={2}
                            >
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <CardMiddle
                                        card_image={news_highlight[0].news_thumbnail}
                                        card_header={news_highlight[0].news_subject}
                                        card_body={news_highlight[0].news_body}
                                        chip_label={news_highlight[0].category_name}
                                        card_footer={news_highlight[0].approvedAt}
                                        target_id={news_highlight[0].news_uid}
                                        handleCardClick={()=>handleCardClick(news_highlight[0].news_uid)}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <CardSmall
                                        card_image={news_highlight[1].news_thumbnail}
                                        card_header={news_highlight[1].news_subject}
                                        card_body={news_highlight[1].news_body}
                                        chip_label={news_highlight[1].category_name}
                                        card_footer={news_highlight[1].approvedAt}
                                        target_id={news_highlight[1].news_uid}
                                        handleCardClick={()=>handleCardClick(news_highlight[1].news_uid)}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <CardSmall
                                        card_image={news_highlight[2].news_thumbnail}
                                        card_header={news_highlight[2].news_subject}
                                        card_body={news_highlight[2].news_body}
                                        chip_label={news_highlight[2].category_name}
                                        card_footer={news_highlight[2].approvedAt}
                                        target_id={news_highlight[2].news_uid}
                                        handleCardClick={()=>handleCardClick(news_highlight[2].news_uid)}
                                    />
                                </Grid>
                            </Grid>
                        </>
                        : null
                    : null
            }
        </>
    )
}