import React, { useState, useEffect } from 'react';
import { getBanner } from '../services/banner-service';
import { getAllCategory } from '../services/category-services';

export default function useCategoryHook(path) {
    const [category_list, setCategoryList] = useState([])

    useEffect(() => {
        fetchCategory()
    }, [])

    const fetchCategory = async () =>{
        let res_news_category_list = await getAllCategory()
        let temp_news_category_list = []
        for(let item of res_news_category_list.data.data){
            // console.log("news_category_item", item);
            temp_news_category_list.push({
                category_id: item.category_id,
                category_name: item.category_name,
                category_index: item.category_index
            })
        }
        temp_news_category_list.sort((a, b) => a.category_index - b.category_index);
        setCategoryList(temp_news_category_list)
    }

    return{
        category_list
    }
}