import React, { useState, useEffect, useRef } from 'react';
import {
    Grid,
    Typography,
} from '@mui/material';
import CardPlayer from '../card/card-player';
import CardMiddle from '../card/card-middle';
import CardSmall from '../card/card-small';

export default function PlayerSection({
    section_title,
    content_list,
}) {
    const [selected_content, setSelectedContent] = useState([])

    const handleCardClick = (selected_id) => {
        let selected_data = content_list.find(({ id }) => id === selected_id)
        setSelectedContent(selected_data);
    }

    useEffect(() => {
        const asyncFn = async () => {
            setSelectedContent(content_list[0])
        }
        asyncFn()
    }, [content_list])
    return (
        <>
            {
                content_list !== undefined ?
                content_list.length > 0 ?
                        <Grid
                            container
                            item
                            xs={12}
                        >
                            <Typography
                                className='section-header-white2'
                                style={{
                                    'border-bottom': '4px solid #FE5907',
                                    'inline-size': '150px'
                                }}
                            >
                                {section_title}
                            </Typography>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    mt: 2,
                                    width: '100%'
                                }}
                            >
                                {
                                    selected_content !== undefined ?
                                        <CardPlayer
                                            url={selected_content.url}
                                            title={selected_content.name}
                                            desc={selected_content.desc}
                                            time={selected_content.time}
                                        /> : null
                                }
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                container
                                flexDirection={'columns'}
                                rowSpacing={2}
                                spacing={2}
                                sx={{
                                    mt: 0,
                                    // pl: 1,
                                    // pr:2
                                    display: {
                                        lg: 'flex',
                                        md: 'flex',
                                        xs: 'none',
                                        sm: 'flex',
                                    },
                                }}
                            >
                                {
                                    content_list.map((item, i) => {
                                        return (
                                            <Grid
                                                item
                                                sm={4}
                                                md={3}
                                            >
                                                <CardMiddle
                                                    card_image={item.thumbnail}
                                                    card_header={item.name}
                                                    card_footer={item.time}
                                                    target_id={item.id}
                                                    handleCardClick={handleCardClick}
                                                />
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                container
                                flexDirection={'rows'}
                                rowSpacing={2}
                                sx={{
                                    mt: 0,
                                    // pl: 1,
                                    // pr:2
                                    display: {
                                        lg: 'none',
                                        md: 'none',
                                        xs: 'flex',
                                        sm: 'none',
                                    },
                                }}
                            >
                                {
                                    content_list.map((item, i) => {
                                        return (
                                            <Grid
                                                item
                                                xs={12}
                                            >
                                                <CardSmall
                                                    card_image={item.thumbnail}
                                                    card_header={item.name}
                                                    card_footer={item.time}
                                                    target_id={item.id}
                                                    handleCardClick={handleCardClick}
                                                />
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                        : null
                    : null
            }

        </>
    )
}