import React, { useState, useEffect, useRef } from 'react';
import {
    Container,
    Grid
} from '@mui/material';
import useNewsHook from '../hooks/news-hook';
import AdMobile from '../components/ad-mobile';
import HeaderApp from '../components/header-app';
import CardSmallNewsHighlight from '../components/card-group/card-small-news-highlight';
import CardSmalleList from '../components/card-group/card-small-list-app';
import ListNews from '../components/list/list-news';
import NewsListXSLayout from '../layouts/news-list/news-list-xs-layout';
import NewsListAPPLayout from '../layouts/news-list/news-list-app-layout';

export default function AppHomePage() {

    const {
        search_str,
        setSearchStr,
        handleSearchSubmit,
        news_all,
        selected_news_category,
        handleCategorySelected
    } = useNewsHook()
    return (
        <>
                <HeaderApp
                />
                <Container
                    disableGutters={true}
                    maxWidth="xs"
                    className='web-bg'
                >
                    <Container
                        disableGutters={true}
                        maxWidth="xs"
                        sx={{
                            display: {
                                md: 'flex',
                                xs: 'flex',
                                sm: 'flex',
                            },
                            flexDirection: 'column',
                        }}
                    >
                        {/* <Grid
                        container
                        spacing={1}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <AdMobile />
                        </Grid>
                    </Grid> */}
                        <Grid
                            container
                            spacing={1}
                            sx={{
                                columnGap: 2,
                                rowGap: 2,
                                pt: 2,
                                pl: 2,
                                pr: 2
                            }}
                        >

                            <NewsListAPPLayout
                                news_all={news_all}
                                search_str={search_str}
                                setSearchStr={setSearchStr}
                                handleSearchSubmit={handleSearchSubmit}
                                selected_news_category={selected_news_category}
                                handleCategorySelected={handleCategorySelected}
                            />
                        </Grid>

                    </Container>

                </Container>
        </>
    )
}
