import axios from 'axios';
import config from '../config.json';

export const getLives = async () => {
    return axios.get(
      config.BASE_API + 'web/streaming?active=true',
      // config.BASE_API + "web/streaming?active=false&streaming_tags='main'",
      {
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
  }