import React, { useState, useEffect } from 'react';
import { getBanner } from '../services/banner-service';

export default function useBannerHook(path) {
    const [banner, setBanner] = useState([])

    useEffect(() => {
        fetchBanner()
    }, [])

    const fetchBanner = async () =>{
        try {
            let res = await getBanner()
            // console.log(res);
            let temp_list = []
            let all_banner = res.data.data
            await all_banner.map((item, index) => {
                temp_list.push({
                    id: item.banner_id,
                    index: index + 1,
                    banner_subject: item.banner_subject,
                    banner_body: item.banner_body,
                    banner_thumbnail: item.banner_thumbnail,
                    banner_image: item.banner_image,
                    banner_link: item.banner_link,
                    active: item.active
                })
            })
            setBanner(temp_list)
        } catch (error) {
            console.log(error);
        }
    }

    return{
        banner
    }
}