import React, { useState, useEffect } from 'react'
import {
    Card,
    Grid,
    CardContent,
    Typography,
    Chip,
    Box
} from '@mui/material';
import ReactPlayer from 'react-player'

export default function CardPlayer({
    url,
    title,
    desc,
    time,
    cf_url
}) {
    return (
        <>
            <Card
                elevation={0}
                sx={{ width: '100%', bgcolor: '#393939' }}
            >
                <CardContent>
                    <Grid
                        container
                        columnSpacing={2}
                        item
                        sx={{ display: 'flex', flexDirection: 'row' }}
                    >
                        <Grid item lg={6} xs={12}>
                            {
                                cf_url === undefined && cf_url !== '' ?
                                    <ReactPlayer
                                        className='react-player'
                                        url={url}
                                        width='100%'
                                        height='400px'
                                    // playing={play}
                                        controls={true}
                                    />
                                    : 
                                    <div dangerouslySetInnerHTML={{ __html: cf_url }} />
                            }
                        </Grid>
                        <Grid
                            item
                            lg={6}
                            xs={12}
                            sx={{
                                mt: 2
                            }}
                        >
                            <Typography
                                className='card-header'
                            >
                                {title}
                            </Typography>
                            <Typography
                                className='list-subtitle'
                            >
                                {desc}
                            </Typography>
                            <Typography
                                className='list-subtitle'
                            >
                                {time}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}