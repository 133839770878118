import React, { useState, useEffect } from 'react'
import {
    ListItem,
    ListItemButton,
    ListItemText,
    List,
    Divider,
    Typography
} from '@mui/material';

export default function ListNews({
    news,
}) {
    const handleChangePage = (uid) =>{
        if (uid === 0) {
            window.location = "/news"
        } else {
            window.location = "/news/" + uid
        }
    }
    return (
        <List
            sx={{
                width: '100%',
                // height: '97%',
                bgcolor: '#393939'
            }}
        >
            <ListItem 
                disablePadding
            >
                <ListItemButton>
                    <ListItemText
                        // primary="ข่าวล่าสุด"
                        primary={
                            <Typography 
                                style={{ 
                                    color: '#FFFFFF', 
                                    paddingBottom:'10px',
                                    fontSize: '28px', 
                                    'border-bottom': '4px solid #FE5907', 
                                    'inline-size': '150px' 
                                }}
                            >
                                ข่าวล่าสุด
                            </Typography>}
                    />
                </ListItemButton>
            </ListItem>
            {
                news.map((item, i) => {
                    return (
                        <>
                            <ListItem
                                disablePadding
                                onClick={() => handleChangePage(item.news_uid)}
                            >
                                <ListItemButton>
                                    <ListItemText
                                        primary={
                                            <Typography 
                                                className='list-title'
                                                // style={{ color: '#FFFFFF', fontSize: '14px' }}
                                            >
                                                {item.news_subject}
                                            </Typography>}
                                        // primary={item.news_subject}
                                        // secondary={item.approvedAt}
                                        secondary={<Typography style={{ color: '#FFFFFF', fontSize: '12px' }}>{item.approvedAt}</Typography>}
                                        onClick={() => handleChangePage(item.news_uid)}
                                    />
                                </ListItemButton>
                            </ListItem>
                            <Divider sx={{ bgcolor: "#757575" }} />
                        </>
                    )
                })
            }
            <ListItem
                disablePadding
                onClick={() => handleChangePage(0)}
            >
                <ListItemButton>
                    <ListItemText
                        onClick={() => handleChangePage(0)}
                        primary={<Typography style={{ color: '#FFFFFF', fontSize: '14px', textAlign:'center', alignItems:'center' }}>ดูข่าวล่าสุดทั้งหมด</Typography>}
                    />
                </ListItemButton>
            </ListItem>
        </List>
    )
}