import axios from 'axios';
import config from '../config.json';

export const getMatchDate = async () => {
    // let token = LocalStorage.getToken();
    return axios.get(
        config.LIVE_SCORE_API + 'premierleague/match_dates',
        {
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const getMatchResultList = async (date) => {
    // let token = LocalStorage.getToken();
    return axios.get(
        config.LIVE_SCORE_API + 'premierleague/results?date=' + date,
        {
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + token
            }
        }
    )
}