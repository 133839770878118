import React, { useState, useEffect, useRef } from 'react';
import {
    Container,
} from '@mui/material';
import Header from '../components/header';
import { SuperSEO } from "react-super-seo";
import HomeMDLayout from '../layouts/home/home-md-layout';
import HomeSMLayout from '../layouts/home/home-sm-layout';
import HomeXSLayout from '../layouts/home/home-xs-layout';
import useNewsHook from '../hooks/news-hook';
import useLivescoreHook from '../hooks/livescore-hook';
import useVideoHook from '../hooks/video-hook';
import useLiveHook from '../hooks/live-hook';
import CookieConsent from "react-cookie-consent";

export default function HomePage() {
    const { news_highlight, news_card, news_list_home, fetchNews } = useNewsHook()
    const { match_list, fetchRecentMatchResult } = useLivescoreHook()
    const { video_list } = useVideoHook()
    const { live_list } = useLiveHook()

    useEffect(() => {
        const asyncFn = async () => {
            await fetchRecentMatchResult()
            await fetchNews("&limit=20")
        }
        asyncFn()
    }, [])
    return (
        <>
            <SuperSEO
                title="Thai Sport Plus"
                description="สถานีกีฬา 24 ชั่วโมง : ติดตามบทวิเคราะห์สัมภาษณ์นักกีฬาอย่างเจาะลึกตรงประเด็น"
                lang="en"
                openGraph={{
                    ogTitle: "Thai sport plus",
                    ogType: "article",
                    ogDescription: "สถานีกีฬา 24 ชั่วโมง : ติดตามบทวิเคราะห์สัมภาษณ์นักกีฬาอย่างเจาะลึกตรงประเด็น",
                    ogImage: {
                        ogImage: "https://thaisportplus.com/static/media/logo.d20e9b6809f1ea4b254c.png",
                        ogImageAlt: "news",
                        ogImageWidth: 1200,
                        ogImageHeight: 630,
                        ogImageType: "image/jpeg",
                    },
                }}
            />
            <Header
                video_list={video_list}
                live_list={live_list}
            />
            <Container
                disableGutters={true}
                maxWidth="false"
                className='web-bg'
            >
                <HomeMDLayout
                    news_highlight={news_highlight}
                    news_card={news_card}
                    news_list={news_list_home}
                    match_list={match_list}
                    video_list={video_list}
                    live_list={live_list}
                />
                <HomeSMLayout
                    news_highlight={news_highlight}
                    news_card={news_card}
                    news_list={news_list_home}
                    match_list={match_list}
                    video_list={video_list}
                    live_list={live_list}
                />
                <HomeXSLayout
                    news_highlight={news_highlight}
                    news_card={news_card}
                    news_list={news_list_home}
                    match_list={match_list}
                    video_list={video_list}
                    live_list={live_list}
                />
            </Container>
            <CookieConsent
                location="bottom"
                buttonText="I accept"
                declineButtonText="Decline"
                cookieName="myWebsiteCookieConsent"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
                onDecline={() => {
                    alert("You have declined cookies.");
                }}
            >
                This website uses cookies to enhance the user experience.{" "}
                <span style={{ fontSize: "10px" }}>Learn more</span>
            </CookieConsent>
        </>
    )
}