import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import useVideoHook from '../hooks/video-hook';
import useLiveHook from '../hooks/live-hook';
import Header from '../components/header';

const PrivacyPolicy = () => {

    const { video_list } = useVideoHook()
    const { live_list } = useLiveHook()
    return (
        <>
            <Header
                video_list={video_list}
                live_list={live_list}
            />
            <Container
                disableGutters={true}
                maxWidth="false"
                className='web-bg'
            >
                {/* <Container> */}
                <Box sx={{ bgcolor: '#333', color: '#fff', p: 3, borderRadius: 2, mt: 0 }}>
                    <Typography variant="h4" gutterBottom sx={{ color: '#FE5907' }}>
                        Privacy Policy
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        Introduction
                    </Typography>
                    <Typography paragraph>
                        Welcome to ThaiSportPlus. We respect your privacy and are committed to protecting your personal information. This
                        privacy policy explains how we collect, use, and share information about you when you use our application.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        Information We Collect
                    </Typography>
                    <Typography paragraph>
                        We may collect the following types of information:
                    </Typography>
                    <ul>
                        <li><Typography paragraph><strong>Personal Information:</strong> Name, email address, and contact details.</Typography></li>
                        <li><Typography paragraph><strong>Usage Data:</strong> Information on how you use our app, such as interactions and preferences.</Typography></li>
                        <li><Typography paragraph><strong>Device Information:</strong> Information about your device, including type, operating system, and unique identifiers.</Typography></li>
                    </ul>

                    <Typography variant="h6" gutterBottom>
                        How We Use Your Information
                    </Typography>
                    <Typography paragraph>
                        We use the information we collect to:
                    </Typography>
                    <ul>
                        <li><Typography paragraph>Provide and maintain our application.</Typography></li>
                        <li><Typography paragraph>Improve and personalize your experience.</Typography></li>
                        <li><Typography paragraph>Communicate with you about updates and promotions.</Typography></li>
                        <li><Typography paragraph>Analyze usage patterns to enhance our services.</Typography></li>
                    </ul>

                    <Typography variant="h6" gutterBottom>
                        How We Share Your Information
                    </Typography>
                    <Typography paragraph>
                        We do not share your personal information with third parties, except in the following circumstances:
                    </Typography>
                    <ul>
                        <li><Typography paragraph>With your consent.</Typography></li>
                        <li><Typography paragraph>To comply with legal obligations.</Typography></li>
                        <li><Typography paragraph>To protect our rights and property.</Typography></li>
                    </ul>

                    <Typography variant="h6" gutterBottom>
                        Your Choices
                    </Typography>
                    <Typography paragraph>
                        You can control and manage your personal information through the settings in our app. You can also contact us to
                        request access, correction, or deletion of your personal information.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        Contact Us
                    </Typography>
                    <Typography paragraph>
                        If you have any questions about this privacy policy, please contact us at <strong>support@thaisportplus.com</strong>.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        Changes to This Privacy Policy
                    </Typography>
                    <Typography paragraph>
                        We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy
                        on this page.
                    </Typography>
                </Box>
            </Container>
        </>
    );
};

export default PrivacyPolicy;
