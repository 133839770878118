import React, { useState, useEffect } from 'react';
import { getVideo } from '../services/video-service';
import { timeDifference } from '../utils/display-utils';

export default function useVideoHook(path) {
    const [video_list, setVideoList] = useState([])

    useEffect(() => {
        fetchVideo()
    }, [])

    const fetchVideo = async () => {
        let res = await getVideo()
        let all_video = res.data.data
        let temp_video_list = []
        all_video.map((item, index) => {
            // console.log(item);
            temp_video_list.push({
                id: item.video_id,
                name: item.video_name,
                thumbnail: item.video_thumbnail,
                url: item.video_url,
                body: item.video_body,
                desc: item.video_desc,
                time: timeDifference(item.createdAt)
            })
        })
        setVideoList(temp_video_list)
    }

    return {
        video_list
    }
}