import React, { useState, useEffect } from 'react'
import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    Chip,
    Box
} from '@mui/material';

export default function CardBig({
    card_image,
    card_header,
    card_body,
    chip_label,
    card_footer,
    target_id,
    handleCardClick
}) {
    return (
        <>
            <Card
                elevation={0}
                sx={{ height: '100%', bgcolor: '#393939' }}
            >
                <CardMedia
                    className='card-big-img'
                    image={card_image}
                    component='img'
                    onClick={() => handleCardClick(target_id)}
                />
                <CardContent
                    onClick={() => handleCardClick(target_id)}
                    sx={{ bgcolor: '#393939' }}
                >
                    <Box
                        container
                        direction="column"
                        justifyContent="space-around"
                        alignItems="flex-start"
                    >
                        <Typography
                            className='card-header'
                        >
                            {card_header}
                        </Typography>
                        <Typography
                            className='card-body-desc'
                            dangerouslySetInnerHTML={{ __html: card_body }}
                        >
                        </Typography>
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <Chip
                                className='card-chip-desc'
                                label={chip_label}
                                color='primary'
                                style={{
                                    backgroundColor: '#FE5A06',
                                    marginRight: 12
                                }}
                                sx={{
                                    '& .MuiChip-label': { fontSize: 12 },
                                }}
                            />
                            <Typography className='card-footer'>
                                {card_footer}
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
}