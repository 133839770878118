import React, { useState, useEffect, useRef } from 'react';
import {
    Typography,
} from '@mui/material';
import InfiniteScroll from "react-infinite-scroll-component";
import CardSmalleList from '../card-group/card-small-list-app';
import CardSmalleListApp from '../card-group/card-small-list-app';

export default function NewsInfiniteSectionApp({
    news_list,
    start_index
}) {
    const [max_news, setMaxNews] = useState(17)

    const handleLoadMore = () => {
        setTimeout(() => {
            let temp_max_news = max_news + 8
            setMaxNews(temp_max_news)
        }, 1500);
    }
    return (
        <>
            <InfiniteScroll
                dataLength={8}
                next={handleLoadMore()}
                hasMore={true}
                // loader={
                //     <Typography
                //         className='card-small-header'
                //     >
                //         Loading...
                //     </Typography>
                // }
            >
                <CardSmalleListApp
                    news={news_list.slice(start_index, 9 + max_news)}
                />
            </InfiniteScroll>
        </>
    )
}