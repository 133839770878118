import React, { useState, useEffect, useRef } from 'react';
import {
    Container,
} from '@mui/material';
import Header from '../components/header';
import { SuperSEO } from "react-super-seo";
import useNewsHook from '../hooks/news-hook';
import useVideoHook from '../hooks/video-hook';
import useLiveHook from '../hooks/live-hook';
import NewsListMDLayout from '../layouts/news-list/news-list-md-layout';
import NewsListSMLayout from '../layouts/news-list/news-list-sm-layout';
import NewsListXSLayout from '../layouts/news-list/news-list-xs-layout';

export default function NewsListPage() {
    const { 
        search_str, 
        setSearchStr, 
        handleSearchSubmit, 
        news_all, 
        selected_news_category,
        handleCategorySelected
     } = useNewsHook()

    // const {search_str, setSearchStr, handleSearchSubmit} = useNewsHook()
    // const { selected_news_category, setSelectedNewsCategory, handleCategorySelected } = useNewsHook()
    
    const { video_list } = useVideoHook()
    const { live_list } = useLiveHook()
    return (
        <>
            <SuperSEO
                title="Thai Sport Plus"
                description="สถานีกีฬา 24 ชั่วโมง : ติดตามบทวิเคราะห์สัมภาษณ์นักกีฬาอย่างเจาะลึกตรงประเด็น"
                lang="en"
                openGraph={{
                    ogTitle: "Thai sport plus",
                    ogType: "article",
                    ogDescription: "สถานีกีฬา 24 ชั่วโมง : ติดตามบทวิเคราะห์สัมภาษณ์นักกีฬาอย่างเจาะลึกตรงประเด็น",
                    ogImage: {
                        ogImage: "https://thaisportplus.com/static/media/logo.d20e9b6809f1ea4b254c.png",
                        ogImageAlt: "news",
                        ogImageWidth: 1200,
                        ogImageHeight: 630,
                        ogImageType: "image/jpeg",
                    },
                }}
            />
            <Header
                video_list={video_list}
                live_list={live_list}
            />
            <Container
                disableGutters={true}
                maxWidth="false"
                className='web-bg'
            >
                <NewsListMDLayout
                    news_all={news_all}
                    search_str={search_str}
                    setSearchStr={setSearchStr}
                    handleSearchSubmit={handleSearchSubmit}
                    selected_news_category={selected_news_category}
                    handleCategorySelected={handleCategorySelected}
                />
                <NewsListSMLayout
                    news_all={news_all}    
                    search_str={search_str}
                    setSearchStr={setSearchStr}
                    handleSearchSubmit={handleSearchSubmit}   
                    selected_news_category={selected_news_category}
                    handleCategorySelected={handleCategorySelected}         
                />
                <NewsListXSLayout
                    news_all={news_all}
                    search_str={search_str}
                    setSearchStr={setSearchStr}
                    handleSearchSubmit={handleSearchSubmit}
                    selected_news_category={selected_news_category}
                    handleCategorySelected={handleCategorySelected}
                />
            </Container>
        </>
    )
}