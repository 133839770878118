import React, { useState, useEffect } from 'react';
import { timeDifference } from '../utils/display-utils';
import { getLives } from '../services/live-service';

export default function useLiveHook(path) {
    const [live_list, setLiveList] = useState([])

    useEffect(() => {
        fetchLive()
    }, [])

    const fetchLive = async () => {
        let res = await getLives()
        let all_live = res.data.data
        let temp_live_list = []
        all_live.map((item, index) => {
            if(item.streaming_tags === 'main'){
                temp_live_list.push({
                    id: item.streaming_id,
                    name: item.streaming_name,
                    thumbnail: item.streaming_thumbnail,
                    url: item.streaming_url,
                    desc: item.streaming_desc,
                    time: timeDifference(item.createdAt)
                })
            }
        })
        setLiveList(temp_live_list)
    }

    return {
        live_list
    }
}