import axios from 'axios';
import config from '../config.json';

export const getNews = async (filter_str) => {
  return axios.get(
    config.BASE_API + 'web/news?sortBy=approvedAt&sortType=desc' + filter_str,
    {
      headers: {
        'Content-Type': 'application/json',
      }
    }
  )
}
export const getNewsDetail = async (uid) => {
  return axios.get(
    config.BASE_API + 'web/news/' + uid,
    {
      headers: {
        'Content-Type': 'application/json',
      }
    }
  )
}