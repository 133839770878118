import React, { useState, useEffect, useRef } from 'react';
import {
    Grid,
    Container,
} from '@mui/material';
import Article from '../../components/article';
import ListNews from '../../components/list/list-news';
import CardMiddle from '../../components/card/card-middle';
import Ad from '../../components/ad';

export default function NewsDetailSMLayoutApp({ news_details, news_all }) {
    const handleCardClick = (uid) =>{
        if (uid === 0) {
            window.location = "/app/news"
        } else {
            window.location = "/app/news/" + uid
        }
    }
    return (
        <>
            <Container
                disableGutters={true}
                maxWidth="xl"
                sx={{
                    display: {
                        md: 'none',
                        xs: 'none',
                        sm: 'flex',
                    },
                    flexDirection: 'column',
                    columnGap: 2,
                    rowGap: 2,
                    pl: 2,
                    pr: 2,
                    pt: 2,
                    pb: 2
                }}
            >
                <Grid
                    direction="row"
                    container
                    spacing={2}
                    rowSpacing={2}
                >
                    <Grid
                        item
                        sm={8}
                    >
                        {
                            news_details !== undefined ?
                                news_details.news_thumbnail !== undefined ?
                                    <Article
                                        title={news_details.news_subject}
                                        author={news_details.username}
                                        create_at={news_details.updatedAt}
                                        update_at={news_details.approvedAt}
                                        body={news_details.news_body}
                                        uid={news_details.news_uid}
                                    />
                                    : null
                                : null
                        }
                    </Grid>
                    <Grid
                        item
                        sm={4}
                    >
                        <ListNews
                            news={news_all.slice(0, 6)}
                        />
                        <Ad/>
                    </Grid>
                    <Grid
                        item
                        sm={12}
                        direction="row"
                        container
                        spacing={2}
                    // sx={{ width: '100%', bgcolor: '#393939' }}
                    >
                        {
                            Array.from({ length: 3 }, (item, index) => {
                                return (
                                    <Grid
                                        item
                                        sm={4}
                                    >
                                        {
                                            news_all !== undefined ?
                                                news_all.length > 0 ?
                                                    <CardMiddle
                                                        card_image={news_all[index + 6].news_thumbnail}
                                                        card_header={news_all[index + 6].news_subject}
                                                        chip_label={news_all[index + 6].category_name}
                                                        card_footer={news_all[index + 6].approvedAt}
                                                        target_id={news_all[index + 6].news_uid}
                                                        handleCardClick={()=>handleCardClick(news_all[index + 6].news_uid)}
                                                    />
                                                    : null
                                                : null
                                        }
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}