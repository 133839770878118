import React, { useState, useEffect, useRef } from 'react';
import {
    Grid,
} from '@mui/material';
import CardMiddle from '../card/card-middle';

export default function CardMiddleCol({ news }) {

    const handleCardClick = (uid) =>{
        if (uid === 0) {
            window.location = "/news"
        } else {
            window.location = "/news/" + uid
        }
    }
    return (
        <>
            {
                news !== undefined ?
                    news.length > 0 ?
                        <Grid
                            container
                            flexDirection={'columns'}
                            rowSpacing={2}
                            spacing={2}
                        >
                            {
                                news.map((item, i) => {
                                    return(
                                        <>
                                        <Grid
                                            item
                                            sm={4}
                                        >
            
                                            <CardMiddle
                                                card_image={item.news_thumbnail}
                                                card_header={item.news_subject}
                                                chip_label={item.category_name}
                                                card_footer={item.approvedAt}
                                                target_id={item.news_uid}
                                                handleCardClick={()=>handleCardClick(item.news_uid)}
                                            />
                                        </Grid>
                                        </>
                                    )
                                })
                            }
                        </Grid>
                        : null
                    : null
            }
        </>
    )
}