import React, { useState, useEffect } from 'react';
import { getMatchDate, getMatchResultList } from '../services/livescore-service';
const moment = require("moment")

export default function useLivescoreHook(path) {

    const [match_list, setMatchList] = useState([])

    const fethcMatchResult = async (match_date) => {
        let match_result = await getMatchResultList(match_date)
        let temp_list = []
        let index = 0
        for (let item of match_result.data.data) {
            const date_object = moment(item.Esd, "YYYYMMDDhhmmss")
            let date = date_object.format('DD MMM').toString()
            let time = date_object.format('HH:mm').toString()
            let team1_score = item.Eps === "NS" ? "0" : item.Tr1
            let team2_score = item.Eps === "NS" ? "0" : item.Tr2
            temp_list.push({
                id: index,
                event_id: item.Eid,
                date: date,
                time: time,
                home_score: team1_score,
                home_name: item.T1[0].Nm,
                home_logo: "https://lsm-static-prod.livescore.com/medium/" + item.T1[0].Img,
                away_score: team2_score,
                away_name: item.T2[0].Nm,
                away_logo: "https://lsm-static-prod.livescore.com/medium/" + item.T2[0].Img,
            })
        }
        setMatchList(temp_list)
    }

    const fetchRecentMatchResult = async () =>{
        let match_date_list = await getMatchDate()
        let index = 0
        let match_date_str = ""
        // setMatchDateList(match_date.data.data)
        let current_date = moment().startOf('day')
        for (let item of match_date_list.data.data) {
            let date_object = moment(item, "YYYYMMDD")
            if (date_object >= current_date) {
                match_date_str = item
                break
            }
            index++
        }
        // console.log(); 
        fethcMatchResult(match_date_str)
    }

    return{
        match_list,
        fetchRecentMatchResult
    }
}