import React, { useState, useEffect } from 'react'
import {
    Grid,
    Button,
    CardContent,
    Typography,
    Chip,
    Box
} from '@mui/material';
import useCategoryHook from '../../hooks/category-hook';
import useNewsHook from '../../hooks/news-hook';

export default function CategoryButtonGroup({
    handleCategorySelected,
    selected_news_category
}) {
    const { category_list } = useCategoryHook()
    return (
        <Grid
            item
            xs={12}
            container
            spacing={1}
        >
            <Grid item>
                <Button
                    variant="contained"
                    sx={{
                        color: 'white',
                        bgcolor: selected_news_category === 0 ? '#FE5907' : '#393939',
                        '&:hover': {
                            backgroundColor: '#FE5907',
                        },
                    }}
                    onClick={() => handleCategorySelected(0)}
                >
                    ทั้งหมด
                </Button>
            </Grid>
            {
                category_list.map((item, i) => {
                    return (
                        <Grid item>
                            <Button
                                variant="contained"

                                sx={{
                                    color: 'white',
                                    // display: 'block',
                                    bgcolor: selected_news_category === item.category_id ? '#FE5907' : '#393939',
                                    '&:hover': {
                                        backgroundColor: '#FE5907',
                                    },
                                }}
                                onClick={() => handleCategorySelected(item.category_id)}
                            >
                                {item.category_name}
                            </Button>
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}