import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
} from '@mui/material';
import useBannerHook from '../hooks/banner-hook';

export default function Banner() {
    let { banner } = useBannerHook()
    
    return (
        <>
            {
                banner.length > 0 ?
                    <>
                        <Box
                            sx={{
                                // paddingTop:2,
                                width: 'auto',
                                height: '100%',
                                bgcolor: '#111111',
                                display: {
                                    lg: 'flex',
                                    md: 'none',
                                    xs: 'none',
                                    sm: 'none',
                                },
                            }}
                            style={{
                                backgroundImage: `url(${banner[0]['banner_image']})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }}
                        >
                        </Box>
                        <Box
                            sx={{
                                width: 'auto',
                                height: 400,
                                bgcolor: '#111111',

                                display: {
                                    lg: 'none',
                                    md: 'flex',
                                    xs: 'none',
                                    sm: 'none',
                                },
                            }}
                            style={{
                                backgroundImage: `url(${banner[0]['banner_image']})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }}
                        >
                        </Box>
                        <Box
                            sx={{
                                width: 'auto',
                                height: 800,
                                bgcolor: '#111111',

                                display: {
                                    md: 'none',
                                    sm: 'flex',
                                    xs: 'none',
                                },
                            }}
                            style={{
                                backgroundImage: `url(${banner[0]['banner_link']})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }}
                        >
                        </Box>
                        <Box
                            sx={{
                                width: 'auto',
                                height: 800,
                                bgcolor: '#111111',

                                display: {
                                    md: 'none',
                                    sm: 'none',
                                    xs: 'flex',
                                },
                            }}
                            style={{
                                backgroundImage: `url(${banner[0]['banner_thumbnail']})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }}
                        >
                        </Box>
                    </>

                    : null
            }
        </>
    )
}
