import React, { useState, useEffect } from 'react'
import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    Chip,
    Box
} from '@mui/material';

export default function CardMiddle({
    card_image,
    card_header,
    card_body,
    chip_label,
    card_footer,
    target_id,
    handleCardClick
}) {
    return (
        <>
            <Card
                elevation={0}
                sx={{ height: '100%', bgcolor: '#393939' }}
            >
                <CardMedia
                    className='card-middle-img'
                    image={card_image}
                    component='img'
                    onClick={() => handleCardClick(target_id)}
                />
                <CardContent
                    onClick={() => handleCardClick(target_id)}
                    sx={{ bgcolor: '#393939' }}
                >
                    <Typography
                        className='card-middle-header'
                    >
                        {card_header}
                    </Typography>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        style={{
                            marginTop: '20px'
                        }}
                    >
                        {
                            chip_label === undefined ? null :
                                <Chip
                                    className='card-chip-desc'
                                    label={chip_label}
                                    color='primary'
                                    style={{
                                        backgroundColor: '#FE5A06',
                                        marginRight: 12
                                    }}
                                    sx={{
                                        '& .MuiChip-label': { fontSize: 12 },
                                    }}
                                />
                        }
                        <Typography className='card-footer'>
                            {card_footer}
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
}