import React, { useState, useEffect, useRef } from 'react';
import {
    Container,
} from '@mui/material';
import Header from '../components/header';
import { SuperSEO } from "react-super-seo";
import useVideoHook from '../hooks/video-hook';
import useLiveHook from '../hooks/live-hook';

export default function LiveScorePage() {
    const { video_list } = useVideoHook()
    const { live_list } = useLiveHook()
    return (
        <>
            <SuperSEO
                title="Thai Sport Plus"
                description="สถานีกีฬา 24 ชั่วโมง : ติดตามบทวิเคราะห์สัมภาษณ์นักกีฬาอย่างเจาะลึกตรงประเด็น"
                lang="en"
                openGraph={{
                    ogTitle: "Thai sport plus",
                    ogType: "article",
                    ogDescription: "สถานีกีฬา 24 ชั่วโมง : ติดตามบทวิเคราะห์สัมภาษณ์นักกีฬาอย่างเจาะลึกตรงประเด็น",
                    ogImage: {
                        ogImage: "https://thaisportplus.com/static/media/logo.d20e9b6809f1ea4b254c.png",
                        ogImageAlt: "news",
                        ogImageWidth: 1200,
                        ogImageHeight: 630,
                        ogImageType: "image/jpeg",
                    },
                }}
            />
            <Header
                video_list={video_list}
                live_list={live_list}
            />
            <Container
                disableGutters={true}
                maxWidth="false"
                className='web-bg'
            >
                
            </Container>
        </>
    )
}