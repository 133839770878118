import React, { useState } from 'react';
import {
    AppBar,
    Container,
    Box,
    Button,
    IconButton,
    Drawer,
    List,
    ListItem,
    Divider,
    Toolbar,
} from '@mui/material';
import logo from '../logo.png';
import MenuIcon from '@mui/icons-material/Menu';
import ListItemComponent from './list/list-item-component';
import SocialIconGroup from './icon-group/social-icon-group';

export default function HeaderApp({ video_list, live_list }) {
    const [is_open_menu, setIsOpenMenu] = useState(false);
    const [anchorElNav, setAnchorElNav] = useState(null);

    const handleOpenNavMenu = (event) => {
        setIsOpenMenu(event.currentTarget);
    };

    const handleChangePage = ( url) => {
        // event.preventdefault()
        setAnchorElNav(null);
        window.location = url
    }
    const handleCardClick = () =>{
        window.location = '/app/home'
    }
    return (
        <>
            <AppBar
                position="fixed"
                sx={{ backgroundColor: '#111111' }}
                style={{
                    'border-bottom': '4px solid #FE5907',
                }}
            >
                <Container
                    maxWidth="false"
                >
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'flex', md: 'none' },
                        }}
                        position="right"
                    >
                        <img
                            src={logo}
                            component="a"
                            onClick={()=>handleCardClick()}
                            className='logo-img'
                            
                        />
                    </Box>
                    
                </Container>
            </AppBar>
        </>
    )
}